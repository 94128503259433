import React from "react";
import { Box, Typography, Link } from "@mui/material";
import Layout from "../components/Layout";
import { MetaData } from "../components/common/meta";
import { Link as RouterLink, PageProps } from "gatsby";

import bg from "../images/subscribe/bg.svg";

import Arrow from "../images/arrow-right.inline.svg";
import simpleLine from "../images/simpleLine.svg";
import SubscribeForm from "../components/SubscribeForm";

const li = {
  px: 0,
  alignItems: "flex-start",
};

const index = {
  backgroundColor: "#1CE7C2",
  color: "#0D2352",
  fontSize: 12,
  lineHeight: "28px",
  width: 28,
  height: 28,
  flex: "0 0 auto",
  textAlign: "center",
  mr: 1.8,
};

const subscribe = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData title="Subscribe" location={location} type="website" />
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "150px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          zIndex: 2,
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Box component="div" sx={{ pt: { xs: 12, md: 22 }, pb: 12 }}>
        <Box
          component="span"
          sx={{
            position: "relative",
            "&:before": {
              content: `url(${simpleLine})`,
              position: "absolute",
              left: 40,
              top: 180,
              display: { xs: "none", xl: "block" },
              zIndex: -1,
            },
          }}
        />
        <Box
          component="div"
          sx={{
            background: "#0C1F47",
            border: "1px solid #4B608D",
            p: { xs: 3, md: 6 },
            maxWidth: 864,
            mx: "auto",
            position: "relative",
            overflow: "hidden",
            "& h2": {
              fontSize: { xs: 30, md: 38 },
              lineHeight: 1.2,
              maxWidth: 488,
              mb: 2,
            },
          }}
        >
          <Box
            component="img"
            src={bg}
            alt=""
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              height: "100%",
              objectFit: "contain",
              pointerEvents: "none",
              opacity: { xs: 0.5, md: 1 },
            }}
          />
          <Typography variant="h2">
            Stay up to date with the latest from{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Jump_
            </Box>
          </Typography>
          <Typography sx={{ maxWidth: 440, mb: { xs: 2.4, sm: 4 } }}>
            Be the first to know when we publish new research, blog posts, and
            announcements.
          </Typography>
          <Box
            component="div"
            sx={{
              mb: 2.4,
              "& .form-container": {
                flexDirection: { xs: "column", sm: "row" },
              },
              "& .MuiTextField-root": {
                width: "100%",
                maxWidth: "100%",
                background: "#0C1F47",
                border: "1px solid #4B608D",
                color: "#fff",
                mr: { sm: 1.6 },
                mb: { xs: 1, sm: 0 },
              },
              "& .MuiInputBase-input": {
                color: "#fff",
                height: 50,
                fontSize: { md: 15 },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                height: 52,
                top: 0,
              },
              "& .MuiButton-root": {
                backgroundColor: "#1CE7C2",
                color: "#091A3D",
                minWidth: { sm: "178px" },
                height: 52,
                fontSize: { md: 15 },
              },
              "& .MuiButton-root:hover": {
                backgroundColor: "#fff",
                color: "#091A3D",
              },
            }}
          >
            <SubscribeForm />
          </Box>

          <Link
            component={RouterLink}
            to="/writing"
            sx={{
              color: "#fff",
              display: "inline-flex",
              alignItems: "center",
              gap: 1,
              fontSize: 12,
              "& svg": {
                transition: ".3s ease-in-out",
                fill: "currentcolor",
              },
              "&:hover svg": {
                transform: "translateX(3px)",
              },
            }}
          >
            Let me read it first <Arrow />
          </Link>
        </Box>
      </Box>
    </Layout>
  );
};

export default subscribe;
